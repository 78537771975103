import React from "react";
import qs from "query-string";
import OverviewAdhocPage from "./adhoc";
import OverviewTaskPage from "./task";

const OverviewPage = () => {
  const url = qs.parse(window.location.search);

  if (url.adhoc === "1") {
    return <OverviewAdhocPage />;
  }

  return <OverviewTaskPage />;
};

export default OverviewPage;

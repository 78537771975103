import { getModuleValue } from "api/module";
import PageSizeContent from "components/PageSizeContent";
import useModuleDetail from "containers/useModuleDetail";
import useTaskDetail from "containers/useTaskDetail";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import tuple from "utils/types/tuple";
import CoverFooter from "./CoverFooter";
import useLang from "utils/hooks/useLang";
import useScroll from "utils/hooks/useScroll";

const CoverPage = () => {
  const { data } = useTaskDetail();
  const { data: clientData } = useModuleDetail("client", data?.value?.client);

  useScroll();

  const { data: projectData } = useQuery(
    tuple(["getModuleValue", "project", data?.value?.project as string]),
    getModuleValue,
    { enabled: !!data?.value?.project }
  );

  const [lang, isLang] = useLang();

  const { data: iesData } = useModuleDetail(
    "ies_company",
    data?.value.ies_company
  );

  const cycle = {
    weekly: {
      en: "WEEKLY REPORT",
      "zh-Hant": "星期檢",
    },
    monthly: {
      en: "MONTHLY REPORT",
      "zh-Hant": "月檢",
    },
    quarterly: {
      en: "QUARTERLY REPORT",
      "zh-Hant": "季檢",
    },
    half_yearly: {
      en: "BI-ANNUALLY REPORT",
      "zh-Hant": "半年檢",
    },
    annually: {
      en: "ANNUALLY REPORT",
      "zh-Hant": "年檢",
    },
  };

  return (
    <PageSizeContent
      className="page_cover"
      size="a4"
      style={{
        backgroundImage: "url('/images/cover.jpg')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
    >
      <div className="text-[18px] absolute z-10 top-12 transform -translate-x-1/2 left-1/2">
        {/* TODO: Update font-style */}
        <img
          src="/images/logo.png"
          alt="logo"
          className="absolute w-[134px] h-auto left-[-165px] top-[-8px]"
        />
        <h6>{iesData?.value?.name?.["zh-Hant"]}</h6>
        <h6 className="font-medium">{iesData?.value?.name?.["en"]}</h6>
      </div>
      <div className="absolute z-20 top-1/2 transform -translate-y-1/2 right-0 left-12 py-12 px-12 bg-[#0C0C3790]">
        <div className="border-[#E61E25] border-l-[9px] pl-8">
          {isLang("en") && (
            <h1 className="text-[46px] font-medium text-white leading-none mb-3">
              IES{" "}
              {data?.value?.type === "maintenance" ? "MAINTENANCE" : "SERVICE"}{" "}
              REPORT
            </h1>
          )}
          {isLang("zh-Hant") && (
            <h2 className="text-[42px] font-medium text-white">
              IES {data?.value?.type === "maintenance" ? "維修保養" : "服務"}
              報告
            </h2>
          )}
          {isLang("en") && (
            <h2 className="text-[38px] font-medium text-white">
              {data?.value?.report_cycle
                ? cycle[data.value.report_cycle]?.en
                : ""}{" "}
              -{" "}
              {moment(data?.value?.cover_datetime || data?.value?.start_date)
                .locale("en")
                .format("MMM YYYY")
                .toUpperCase()}
            </h2>
          )}
          {isLang("zh-Hant") && (
            <h4 className="text-[32px] font-medium text-white">
              {data?.value?.report_cycle
                ? cycle[data.value.report_cycle]?.["zh-Hant"]
                : ""}{" "}
              -{" "}
              {moment(data?.value?.cover_datetime || data?.value?.start_date)
                .locale("zh-hk")
                .format("YYYY年MMM")
                .toUpperCase()}
            </h4>
          )}
          <h5 className="text-[29px] font-medium text-white mt-4">
            {clientData?.value?.company_name?.[lang]}
          </h5>
          <div className="flex justify-between mt-4">
            <h5 className="text-[24px] text-white basis-full font-normal">
              {projectData?.value?.title?.[lang]}
            </h5>
          </div>
        </div>
      </div>
      <CoverFooter type="adhoc" />
    </PageSizeContent>
  );
};

export default CoverPage;

/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { memo, useState } from "react";

import { Route, Switch } from "react-router-dom";

import routes from "config/routes";
import OverviewPage from "pages/OverviewPage";
import CoverPage from "pages/CoverPage";
import AppendixPage from "pages/AppendixPage";
import SummaryPage from "pages/SummaryPage";
import ProductPage from "pages/ProductPage";
import ReadyContext from "./ReadyContext";
import LoadingReadyIndicator from "./LoadingReadyIndicator";
import CoverAdhocPage from "pages/CoverPage/adhoc";
import SummaryAdhocPage from "pages/SummaryPage/adhoc";
import AppendixAdhocPage from "pages/AppendixPage/adhoc";
import ProductAdhocPage from "pages/ProductPage/adhoc";

require("moment/locale/zh-cn");
require("moment/locale/zh-hk");

function RootNavigation() {
  const [ready, setReady] = useState(false);

  return (
    <>
      <LoadingReadyIndicator setReady={setReady} />
      <ReadyContext.Provider value={ready}>
        <Switch>
          <Route component={CoverPage} exact path={routes.cover()} />
          <Route component={OverviewPage} exact path={routes.overview()} />
          <Route component={AppendixPage} exact path={routes.appendix()} />
          <Route component={SummaryPage} exact path={routes.summary()} />
          <Route component={ProductPage} path={routes.product("")} />
          <Route component={CoverAdhocPage} path={routes.coverAdhoc()} />
          <Route component={SummaryAdhocPage} path={routes.summaryAdhoc()} />
          <Route component={AppendixAdhocPage} path={routes.appendixAdhoc()} />
          <Route component={ProductAdhocPage} path={routes.productAdhoc()} />
        </Switch>
      </ReadyContext.Provider>
    </>
  );
}

export default memo(RootNavigation);
